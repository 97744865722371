import { createTheme, adaptV4Theme } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';

// Create a theme instance.
const theme = createTheme(adaptV4Theme({
  palette: {
    primary: {
      main: '#1a73e8',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
    },
    text: {
      primary: "#222", 
    }
  }, 
  overrides: {
    // Style sheet name ⚛️
    MuiButton: {
      // Name of the rule
      root: {
        // Some CSS
        textTransform: 'capitalize',
      },
    },
    MuiCard: {
      root: {
        borderRadius: '8px'
      }
    },
    MuiTypography: {
      colorPrimary: {
        color: '#3880ff !important'
      }
    }
  }
}));

export default theme;

