import React from 'react';
import Head from 'next/head';
// import { useRouter } from 'next/router'; 
import { AppProps } from 'next/app';    

import { StyledEngineProvider } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { CacheProvider } from '@emotion/react';
import CssBaseline from '@material-ui/core/CssBaseline'; 
import createCache from '@emotion/cache';
import theme from 'constants/theme';
import 'src/styles.css';

// import Botd from "@fpjs-incubator/botd-agent";
import TagManager from 'react-gtm-module';  
import * as Sentry from "@sentry/browser"; 

export const cache = createCache({ key: 'css', prepend: true });

let tagManagerArgs = {
  gtmId: typeof process.env["NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID"] !== 'undefined' && process.env["NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID"] !== '' ? process.env["NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID"] : '',
  dataLayer: {}
};

declare global {
    interface Window { 
      ip: any;
    }
}

// const options = {
//   autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
//   debug: false, // enable logs
// };  

const isTextSelected = (window : any) => {
  const selObj = window.getSelection();
  // console.log('selObj ', selObj)
  
  if (selObj && selObj.anchorNode !== null) {
    var selRange = selObj.getRangeAt(0); 
    // console.log('selRange ', selRange) 
    // console.log(selRange.startContainer.nodeName)

    // console.log('element ', element, element.nodeName) 

    // If right click on text but nothing selected
    if (selRange.startOffset - selRange.endOffset !== 0) {
      return true;
    } else if (
      // If selected text from different types of nodes (ctrl + a for example)
      (selRange.startContainer.nodeName === '#text' || selRange.endContainer.nodeName === '#text') &&
      (selRange.startContainer.nodeName !== selRange.endContainer.nodeName)) { 
        return true; 
      }
  } 

  return false;
}; 

export default function MyApp(props: AppProps) {
  // console.log(process.env["NEXT_PUBLIC_HOSTNAME"])
  let { Component, pageProps } = props;   

  // console.log("GOOGLE_TAG_MANAGER_ID ", GOOGLE_TAG_MANAGER_ID)
  // console.log("COMPANY_NAME ", COMPANY_NAME)

  // const [domainState, setDomainState] = React.useState({})

  // const router = useRouter(); 

  const getIp = async () => {
    try {
      const ipRequest = await fetch(`${process.env["NEXT_PUBLIC_API_HOST"]}/v1/search/ip`);  
      const ipRequestJson = await ipRequest.json(); 

      if (typeof ipRequestJson.ip !== 'undefined') {
        window.ip = ipRequestJson.ip;
      } 
    } catch (err) {
      console.log(err)
    } 
  }

  React.useEffect(() => {  
    const allowedElements = ['A', 'INPUT']; 

    if (typeof window.ip === 'undefined') {
      getIp();
    }  

    // console.log('process.env["NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID"] ', process.env["NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID"])
    window.oncontextmenu = (e) => {
      //console.log('e ', e)

      const element = e.target as HTMLElement;

      // console.log('element ', element.classList)

      // console.log('!allowedElements.includes(element.nodeName) ', !allowedElements.includes(element.nodeName))
      // console.log('!isTextSelected(element, window) ', !isTextSelected(element, window))

      if (element && process.env["NEXT_PUBLIC_NODE_ENV"] == 'production') {
        if (!allowedElements.includes(element.nodeName) && !isTextSelected(window)) {
          // alert(element.nodeName !== 'IMG')
          // console.log('log 1')
          if (element.closest(".allowRightClick") === null) {
            // console.log('log 2')
            e.preventDefault();
          } 
        } else if (element.nodeName == 'IMG' && isTextSelected(window)) {
          // console.log('log 3')
          e.preventDefault();
        }
      }   
    }

    let body = document.getElementById('mainWrap'); 
    if (body !== null) {
      body.classList.remove('initializing');
    }
 
    if (process.env["NEXT_PUBLIC_NODE_ENV"] === 'production' && process.browser && typeof process.env["NEXT_PUBLIC_COMPANY_NAME"] !== 'undefined') {
      const santryActiveCompanies = ['Eloply'];

      if (santryActiveCompanies.includes(process.env["NEXT_PUBLIC_COMPANY_NAME"])) {
        Sentry.init({
          dsn: "https://8145dc33e3654b80ba05506d2532f2ec@o1148251.ingest.sentry.io/6219665",
          environment: "production",
          
          // Set tracesSampleRate to 1.0 to capture 100%
          // of transactions for performance monitoring.
          // We recommend adjusting this value in production
          tracesSampleRate: 1.0,
        }); 
      }

      if (typeof process.env["NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID"] !== 'undefined' && process.env["NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID"] !== '') { 
        TagManager.initialize(tagManagerArgs)
      } 
      
      // https://www.npmjs.com/package/react-facebook-pixel
      // import('react-facebook-pixel')
      //   .then(module => module.default)
      //   .then(ReactPixel => {
      //     ReactPixel.init('477065255960023', undefined, options);
      //     ReactPixel.pageView();
      //     router.events.on('routeChangeComplete', () => {
      //       ReactPixel.pageView();
      //     }) 
      //   })  
    }
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement!.removeChild(jssStyles);
    }

    // Bot detection
    // https://fingerprintjs.com/products/bot-detection/
    // Initialize an agent at application startup.
    // const botdPromise = Botd.load({
    //   publicKey: "rilMnIYaqSn8WiaU5QWWC3TE",
    // }); 

    // (async () => {
    //   // Get the bot detection result when you need it.
    //   // Result will contain the requestId property,
    //   // that you can securely verify on the server.
    //   const botd = await botdPromise;
    //   const result = await botd.detect(); 
    //   (window as any).botdResult = result;
    //   // console.log(result);
    // })();
  }, []);

  return (
    <CacheProvider value={cache}>  
        <Head>
          <title>{process.env["NEXT_PUBLIC_COMPANY_NAME"]}</title>
          <meta name="viewport" content="initial-scale=1, width=device-width" />
        </Head> 
        <StyledEngineProvider injectFirst> 
          <ThemeProvider theme={theme}>  
            {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
            <CssBaseline />
            <Component {...pageProps} />  
          </ThemeProvider>  
        </StyledEngineProvider>
      
    </CacheProvider>
  );
}
